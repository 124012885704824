var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("input", {
    attrs: { readonly: "", name: "Zip code" },
    domProps: { value: _vm.formattedValue },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }