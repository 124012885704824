
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ZipCodeTableComponent extends Vue {
  @Prop()
  readonly value?: number;

  get formattedValue(): string {
    const x = this.value
      ?.toString()
      .replace(/\D/g, '')
      .match(/(\d{0,5})(\d{0,4})/) || [''];
    return !x[2] ? x[1] : `${x[1]}-${x[2]}`;
  }
}
