import ApplicantSelectRecruiterDropDown from '@/components/Table/Elements/ApplicantSelectRecruiterDropDown.vue';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import PatchScoreTableElementComponent from '@/components/Table/Elements/PatchScoreTableElementComponent.vue';
import PhoneNumberTableComponent from '@/components/Table/Elements/PhoneNumberTableComponent.vue';
import ShowSocialSecurityComponent from '@/components/Table/Elements/ShowSocialSecurityComponent.vue';
import ZipCodeTableComponent from '@/components/Table/Elements/ZipCodeTableComponent.vue';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import {
  ColumnOrder,
  TableConfigurations,
  TableFilterItem,
  TableFilterTypes
} from '@/models/Table/Legacy/LegacyTable';
import { tableConfigurations } from '@/models/Table/Legacy/LegacyTableConfiguration';
import Routes from '@/router/Routes';

const configs: TableConfigurations = {
  firstName: {
    filterItem: new TableFilterItem({
      label: 'First name',
      column: 'firstName',
      filterType: TableFilterTypes.STRING
    })
  },
  lastName: {
    component: {
      componentRef: LinkElementComponent,
      componentProps: (_cellData, rowData: any) => {
        return {
          linkTo: {
            name: Routes.APPLICANT_DETAILS,
            params: { id: rowData.id }
          },
          displayString: rowData.lastName
        };
      }
    },
    filterItem: new TableFilterItem({
      label: 'Last name',
      column: 'lastName',
      filterType: TableFilterTypes.STRING
    })
  },
  email: {
    type: 'string',
    filterItem: new TableFilterItem({
      label: 'Email',
      column: 'email',
      filterType: TableFilterTypes.STRING
    })
  },
  city: {
    type: 'string',
    filterItem: new TableFilterItem({
      label: 'City',
      column: 'city',
      filterType: TableFilterTypes.STRING
    })
  },
  zipCode: {
    component: ZipCodeTableComponent,
    type: 'number',
    filterItem: new TableFilterItem({
      label: 'Zip Code',
      column: 'zipCode',
      filterType: TableFilterTypes.STRING
    })
  },
  homePhone: {
    component: PhoneNumberTableComponent,
    type: 'number',
    filterItem: new TableFilterItem({
      label: 'Home Phone',
      column: 'homePhone',
      filterType: TableFilterTypes.STRING
    })
  },
  patchScore: {
    component: PatchScoreTableElementComponent,
    type: 'number',
    filterItem: new TableFilterItem({
      label: 'Patch score',
      column: 'patchScore',
      filterType: TableFilterTypes.NUMBER
    })
  },
  isArchived: {
    type: 'boolean',
    filterItem: new TableFilterItem({
      label: 'Is Archived',
      column: 'isArchived',
      filterType: TableFilterTypes.BOOLEAN
    })
  },
  socialSecurityNumber: {
    component: {
      componentRef: ShowSocialSecurityComponent,
      componentProps: (ssn: any): Record<string, unknown> => {
        return {
          ssnString: ssn
        };
      }
    }
  },
  workPhone: {
    component: PhoneNumberTableComponent,
    type: 'number'
  },
  managerId: {
    component: {
      componentRef: ApplicantSelectRecruiterDropDown,
      componentProps: (_cellData, rowData: Applicant) => {
        return {
          applicant: rowData
        };
      }
    },
    columnTitle: 'Recruiter',
    type: 'number'
  }
};

export const applicantTableConfigurations = {
  ...tableConfigurations,
  ...configs
};

export const applicantCollectionTableColumnOrderConfig: ColumnOrder = {
  pinned: ['lastName', 'firstName', 'email'],
  unpinned: [
    'homePhone',
    'socialSecurityNumber',
    'zipCode',
    'middleName',
    'address1',
    'address2',
    'city',
    'state',
    'workPhone',
    'managerId'
  ]
};
